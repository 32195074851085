import React from 'react';
import { Link } from 'gatsby';
import { useStoryblokState } from 'gatsby-source-storyblok';
import { getExcerpt } from '@storyofams/storyblok-toolkit';
import FancyTitle from './fancy-title';
import ImagePane from './image-pane';

function Post({ post }) {
  const storyBlokPost = useStoryblokState(post);
  const { content } = storyBlokPost;
  const title = storyBlokPost.name || storyBlokPost.slug;
  const featuredImg = content?.hero?.filename || '';

  return (
    <Link
      key={storyBlokPost.uuid}
      to={`/${storyBlokPost.full_slug}`}
      className="mx-auto aspect-[134/85] w-full relative group overflow-hidden"
    >
      <article
        itemScope
        itemType="http://schema.org/Article"
        className="bg-slate-100 dark:bg-latori-green-dark shadow-lg h-full"
      >
        {featuredImg
          && (
          <img
            src={`${featuredImg}/m/600x0`}
            alt={storyBlokPost.name || storyBlokPost.slug}
            className="w-full h-full object-cover absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
            loading="lazy"
          />
          )}
        <div className="bg-latori-green-dark opacity-90 w-full h-full absolute bottom-0 left-0 transition-[height] group-hover:h-[0%]" />
        <div className="w-full h-full absolute top-0 left-0 flex flex-col justify-center items-start px-4 group-hover:opacity-0 transition-opacity text-white">
          <h2 className="text-2xl" itemProp="headline">{title}</h2>
        </div>
      </article>
    </Link>
  );
}

function BlogGridView(props) {
  const { posts } = props;
  const [firstPost] = React.useState(useStoryblokState(posts[0]?.node));

  return (
    <>
      <div className="mt-12">
        <ImagePane
          breakpoint="xl"
          image={(
            <Link to={`/${firstPost.full_slug}`} className="w-full">
              {firstPost?.content?.hero?.filename
                && (
                <img
                  src={`${firstPost?.content?.hero?.filename}/m/900x0`}
                  alt={firstPost.name || firstPost.slug}
                  className="w-full"
                />
                )}
            </Link>
          )}
          title={(
            <Link to={`/${firstPost.full_slug}`}>
              <FancyTitle
                text={firstPost.name || firstPost.slug}
                fontSize="text-[24px] lg:text-3xl"
                marginBottom="mb-2"
                tag="h2"
              />
            </Link>
          )}
          desktopImageWidth="w-full lg:w-3/5"
          desktopTitleWidth="w-full lg:w-2/5"
          desktopTitleVerticalPosition="center"
          content={
              firstPost.content && firstPost.content.content
                  && <p className="pt-4 leading-6" dangerouslySetInnerHTML={{ __html: (getExcerpt(firstPost.content.content, { maxLength: 140 })) }} />
          }
        />
      </div>

      <div className="mt-[60px] lg:mt-20 grid grid-cols-1 xl:grid-cols-3 md:grid-cols-2 gap-2 md:gap-4" id="blog-post-list">
        {posts.slice(1, posts.length).map((entry) => (
          <Post key={entry.node.uuid} post={entry.node} />
        ))}
      </div>
    </>
  );
}

export default BlogGridView;
